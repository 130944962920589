<template>
  <div class="accUnbundling">
    <!-- accUnbundling 账号解绑 -->
    <div class="nav">
      <div class="nav_title">账号解绑</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="accUnbundling-main">
      <!-- <form ref="form"> -->
      <div style="margin-bottom: 1.5rem">请完成以下认证</div>
      <div style="margin-bottom: 1.5rem">请输入{{ newPhone }}收到的短信验证码</div>
      <div class="accUnbundling-main-input">
        <input v-model="codeInput" class="code-input" type="text" placeholder="请输入短信验证码" />
        <button v-if="show == 0" class="getCode" @click="getVCode">获取验证码</button>
        <button v-if="show == 1" class="getCode">已发送{{ count }}秒</button>
        <button v-if="show == 2" class="getCode" @click="getVCode">重新获取</button>
      </div>
      <button class="next" @click="next">下一步</button>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { getVerifyCode, getCode } from "@/api/person";
import { Toast } from "vant";

export default {
  data() {
    return {
      cancel,
      phone: "",
      newPhone: "",
      // code: '935582',
      codeInput: "",
      show: 0,
      count: "",
      timer: null,
    };
  },
  created() {
    this.getInfoList();
    // console.log('账号解绑', window.location.href)
  },
  methods: {
    toHome() {
      this.$router.back();
    },
    toBack() {
      this.$router.back();
    },
    // 获取用户信息
    // async getInfoList() {
    //   const res = await getAccInfo()
    //   console.log(res.data)
    //   this.phone = res.data.phone
    //   this.newPhone = this.phone.substring(0, 3) + '****' + this.phone.substring(7, 11)
    //   console.log(this.newPhone)
    //   console.log(this.phone)
    // },
    getInfoList() {
      this.infoData = JSON.parse(sessionStorage.getItem("personInfo"));
      this.phone = this.infoData.phone;
      this.newPhone =
        this.phone.substring(0, 3) + "****" + this.phone.substring(7, 11);
      // console.log(this.infoData)
    },
    // 获取验证码
    async getVCode() {
      // console.log('获取验证码')
      const res = await getVerifyCode({
        phone: this.phone,
        appKey: "ZHONGHEZHIHUI123!@#",
      });
      // console.log(res)
      if (res.code === 0) {
        Toast.success("已发送验证码");
        const times = 60;
        if (!this.timer) {
          this.count = times;
          this.show = 1;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= times) {
              this.count--;
            } else {
              this.show = 2;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    // 点击下一步，并验证解绑验证码
    async next() {
      // console.log(this.codeInput)
      // 验证验证码
      const res = await getCode({
        phone: this.phone,
        code: this.codeInput,
      });
      // console.log(res)
      if (res.code === 0) {
        this.$router.push("/accBinding");
      } else {
        // this.$router.push('/accUnbundling')
      }
      // this.$router.push('/accBinding')
    },
  },
};
</script>

<style lang="scss" scoped>
.accUnbundling {
  // background: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .accUnbundling-main {
    flex: 1;
    // box-sizing: border-box;
    padding: 4rem 1rem 0 1rem;
    background: #f3f3f3;
    .accUnbundling-main-input {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .code-input {
        box-sizing: border-box;
        padding-left: 1rem;
        border-radius: 5px;
        border: 1px solid #d2d2d2;
        outline: none;
        width: 60%;
      }
      .getCode {
        box-sizing: border-box;
        padding: 0 1rem;
        font-size: 0.8rem;
        border-radius: 8px;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        border: 0;
        background: #db2020;
        width: 35%;
      }
    }
    .next {
      color: #fff;
      height: 2.5rem;
      border: 0;
      width: 100%;
      line-height: 2.5rem;
      text-align: center;
      box-sizing: border-box;
      border-radius: 8px;
      background: #db2020;
    }
  }
}
</style>
